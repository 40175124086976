import React from "react";
import { Route } from "react-router-dom";

const SigninComponent = React.lazy(() => import("../pages/Signin"));
// const JiraSigninComponent = React.lazy(() => import("../pages/JiraSignin"));

export const AUTH_ROUTES = [
    <Route index element={<SigninComponent />} key={"AUTH_ROUTE"}></Route>,
    // <Route path="/jirasignin" element={<JiraSigninComponent />}></Route>,
];
