
import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import GenericForm from "../../edvenswa.emportal.common/pages/GenericForm";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import {
  isValidDate,
  isValidTitle,
} from "../../edvenswa.emportal.common/validation/Validation";
import { ASSOCIATE_COMPANY_FIELD_ID } from "../../edvenswa.emportal.console/constants/Constants";
import {
  HOLIDAY_COMPANY_FIELD_ID,
  HOLIDAY_DATE_FIELD_ID,
  HOLIDAY_NAME_FIELD_ID
} from "../constants/Constants";
import { getHolidayFields } from "../fields/GetFields";

const AddHolidays = (props) => {
  const {
    setIsDrawerOpen,
    setFilteredElements,
    setElements,
    onSuccess,
    onError,
    companies,
    tenantId,
  } = props;
  const [formData, setFormData] = useState({});
  const [image, setImage] = useState(null);
  const fields = [
    HOLIDAY_DATE_FIELD_ID,
    HOLIDAY_NAME_FIELD_ID,
    HOLIDAY_COMPANY_FIELD_ID,
  ];
  const formFields = getHolidayFields(fields);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const currentYear = moment().year();
  const company = companies.filter((data) => data.id === tenantId);
  const name = [company[0].name, 'ALL'];

  const handleChange = (field_name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      company: "NONE",
    }));
    switch (field_name) {
      case HOLIDAY_DATE_FIELD_ID: {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [field_name]: value,
        }));
        if (!isValidDate(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: { message: "Date must be within the current year" },
          }));
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
        }
        break;
      }
      case HOLIDAY_NAME_FIELD_ID: {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [field_name]: value,
        }));
        if (!isValidTitle(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field_name]: { message: "Title should be at least 3 characters and first letter should be capital" },
          }));
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[field_name];
            return updatedErrors;
          });
        }
        break;
      }
      case ASSOCIATE_COMPANY_FIELD_ID: {
        setFormData((prevFormData) => ({
          ...prevFormData,
          company: value === "ALL" ? "ALL" : "NONE",
        }));
        break;
      }
      default: {
        throw new Error("Invalid field name");
      }
    }


  };
  console.log(formData);

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const updatedFormData = {
      ...formData,
      // logo: image ? image : HOLIDAY_DEFAULT_IMAGE,
      tenant: company[0],
    };
    if (Object.keys(errors).length === 0) {
      setLoading(!loading);
      axiosInstance
        .post("/holiday/create", updatedFormData)
        .then((response) => {
          setLoading(false);
          setIsDrawerOpen(false);
          console.log(response.data, "dfcfgghv");
          if (response.data) {
            setFilteredElements(response.data);
            setElements(response.data);
            onSuccess("Holiday Added Successfully");
          } else {
            onSuccess("Holiday Already Exist");
          }
        })
        .catch((error) => {
          setLoading(false);
          onError(error);
        });
    }
  };

  const renderCustomInput = (field, key) => {
    if (field.type !== "radio") {
      return (
        <>
          {field.select && field.id === ASSOCIATE_COMPANY_FIELD_ID ? (
            <Autocomplete
              size="small"
              sx={{
                display: "inline-block",
                "& .MuiAutocomplete-inputRoot": {
                  fontSize: "0.75rem",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                },
                "& .MuiAutocomplete-input": {
                  paddingTop: "0",
                },
                "& .MuiInputBase-root": {
                  minWidth: "0",
                },
                "& .MuiIconButton-root": {
                  padding: "4px",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "0.75rem",
                },
              }}

              fullWidth
              // disabled
              onChange={(event, newValue) => {
                handleChange(field.name, newValue);

              }}
              // readOnly
              defaultValue={company[0].name}
              InputLabelProps={{ shrink: true, style: { fontSize: "0.75rem" } }}
              InputProps={{ style: { font: "small-caption" } }}
              options={name}
              // getOptionLabel={(option) => option.name}
              // getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li {...props} style={{ fontFamily: 'Lucida Grande' }}>
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Company" InputProps={{
                  ...params.InputProps,
                  style: { fontFamily: 'Lucida Grande' },
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Lucida Grande' },
                }} />
              )}
            // noOptionsText={
            //   <IconButton onClick={handleOpenDialog}>
            //     <Add /> Create New
            //   </IconButton>
            // }
            />
          ) : (
            <TextField
              {...field}
              fullWidth
              required
              size="small"
              inputProps={{ style: { font: "small-caption" ,fontFamily:"Lucida Grande"} }}
              InputLabelProps={{style: { font: "small-caption",fontFamily:"Lucida Grande" } }}
              key={key}
              label={field.label}
              error={errors[field.name] ? true : false}
              helperText={errors?.[field.name]?.message}
              defaultValue={
                formData && formData[field.name]
                  ? field.name === HOLIDAY_DATE_FIELD_ID
                    ? moment(formData[field.name], "YYYY-MM-DD").format(
                      "YYYY-MM-DD"
                    )
                    : formData[field.name]
                  : ""
              }
              onChange={(event) => handleChange(field.name, event.target.value)}
            ></TextField>
          )}
        </>
      );
    } else {
      return <React.Fragment />;
    }
  };

  return (
    <GenericForm
      formData={formData}
      formFields={formFields}
      errors={errors}
      handleChange={handleChange}
      handleSubmit={handleSave}
      renderCustomInput={renderCustomInput}
      setIsDrawerOpen={setIsDrawerOpen}
      image={image}
      setImage={setImage}
      setLoading={setLoading}
      loading={loading}
      onError={onError}
    />
  );
};

export default AddHolidays;
